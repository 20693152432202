import React from 'react'
import PrivacyPolicy from '../../components/common/PrivacyPolicy/PrivacyPolicy'
import Layout from '../../components/de/Layout/Layout'

const Privacy = () => (
  <Layout
    title='Datenschutz | the.good.code; - Ihr Leitfaden zum Schutz Ihrer Daten'
    description='Auf unserer Seite zum Datenschutz erfahren Sie, wie wir Ihre persönlichen Daten erfassen, verwenden und schützen. Erfahren Sie mehr über Ihre Datenschutzrechte und wie wir Ihre Daten schützen.'
  >
    <PrivacyPolicy />
  </Layout>
)

export default Privacy
